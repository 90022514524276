import axios from 'axios';
const API_URL = process.env.VUE_APP_BASE_API_URL;

class TodoSerivece {
    getTodoItems() {
        return axios.get(`${API_URL}ToDoItems`);
    }
    getTodoItem(id) {
        return axios.get(`${API_URL}ToDoItems/${id}`);
    }
    createItem(name, validity) {
        return axios.post(`${API_URL}ToDoItems`, {
            "title": name,
            "validity": validity
        });
    }
    deleteTodoItem(id) {
        return axios.delete(`${API_URL}ToDoItems/${id}`);
    }
    updateTodoItem(id, name, validity) {
        return axios.put(`${API_URL}ToDoItems/${id}`, {
            "title": name,
            "validity": validity
        });
    }
}

export default new TodoSerivece();
