<template>
  <div class="container">
    <h4>Lista Zadań</h4>
    <button @click="showDialog" class="add-button">Dodaj pozycje</button>
    <!-- <h3>{{ content }}</h3> -->
    <ul class="responsive-table">
      <li class="table-header">
        <div class="col col-0">Id</div>
        <div class="col col-4">Title</div>
        <div class="col col-3"></div>
      </li>
      <!-- new Date(cont.birthDate).toString().padStart(2, "0") new Date(cont.birthDate).toDateString() getFormattedDate(cont.birthDate)-->
      <li class="table-row" v-for="cont in content" :key="cont.id">
        <div class="col col-0" data-label="Id">{{ cont.id }}</div>
        <div class="col col-4" data-label="Tytuł">{{ cont.title }}</div>
        <div class="col col-3" data-label="">
          <button class="delete-button" @click="deleteItem(cont.id)">Usuń</button>
          <button class="def-button" @click="showEditDialog(cont)">Edytuj</button>
          <router-link
            class="edit-button"
            :to="{ name: 'todoItem', params: { id: cont.id } }"
          >
            Definiuj
          </router-link>
        </div>
      </li>
    </ul>
    <Dialog
      v-model:modelValue="isDialogVisible"
      :title="editId == 0 ? 'Stwórz zadanie' : 'Edytuj zadanie'"
    >
      <template #default>
        <!-- <label>
          Validity:
          <input v-model.number="dialogValidity" type="number" />
        </label> -->
        <div class="form-group">
          <label for="title">Tytuł</label>
          <input
            type="text"
            v-model="dialogTitle"
            maxlength="20"
            id="title"
            class="form-control"
            required
          />
        </div>
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="save">Zapisz</button>
        <button class="delete-button" @click="hideDialog">Zamknij</button>
      </template>
    </Dialog>
  </div>
</template>
<script>
import TodoService from "../services/TodoService";
import Dialog from "./SubComponents/Dialog.vue";

export default {
  name: "TodoItems",
  components: {
    Dialog,
  },
  data() {
    return {
      content: "",
      isDialogVisible: false,
      dialogTitle: "",
      dialogValidity: 0,
      editId: 0,
    };
  },
  mounted() {
    this.getTodo();
  },
  methods: {
    getTodo() {
      TodoService.getTodoItems().then(
        (response) => {
          this.content = response.data;
        },
        (error) => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    showDialog() {
      this.dialogTitle = "";
      this.dialogValidity = 0;
      this.editId = 0;
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.isDialogVisible = false;
      this.dialogTitle = "";
      this.dialogValidity = 0;
      this.editId = 0;
    },
    showEditDialog(cont) {
      this.dialogTitle = cont.title;
      this.dialogValidity = cont.validity;
      this.editId = cont.id;
      this.isDialogVisible = true;
    },
    save() {
      if (this.editId) {
        this.edit();
        return;
      }
      TodoService.createItem(this.dialogTitle, this.dialogValidity).then(() => {
        this.hideDialog();
        this.dialogTitle = "";
        this.dialogValidity = 0;
        this.getTodo();
      });
    },
    edit() {
      TodoService.updateTodoItem(this.editId, this.dialogTitle, this.dialogValidity).then(
        () => {
          this.hideDialog();
          this.dialogTitle = "";
          this.dialogValidity = 0;
          this.editId = 0;
          this.getTodo();
        }
      );
    },
    deleteItem(id) {
      TodoService.deleteTodoItem(id).then(() => {
        this.getTodo();
      });
    },
  },
};
</script>
<style>
.container {
  max-width: 2000px;
  margin-left: 1%;
  margin-right: 1%;
  padding-left: 10px;
  padding-right: 10px;
}

h2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
}

h2 small {
  font-size: 0.5em;
}

.responsive-table li {
  border-radius: 3px;
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.responsive-table .table-header {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.responsive-table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.responsive-table .col-0 {
  flex-basis: 5%;
}
.responsive-table .col-1 {
  flex-basis: 10%;
}

.responsive-table .col-2 {
  flex-basis: 20%;
}

.responsive-table .col-3 {
  flex-basis: 30%;
}

.responsive-table .col-4 {
  flex-basis: 40%;
}

button,
.edit-button {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.edit-button {
  background-color: #2196f3;
  color: white;
}

.edit-button:hover {
  background-color: #1976d2;
}
.def-button {
  background-color: #6c7a89;
  color: white;
}

.def-button:hover {
  background-color: black;
}

.save-button {
  background-color: #4caf50;
  color: white;
}

.save-button:hover {
  background-color: #388e3c;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #c62828;
}

.add-button {
  margin-top: 1rem;
  background-color: #4caf50;
  color: white;
}

.add-button:hover {
  background-color: #3e8e41;
}

@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }

  .responsive-table li {
    display: block;
  }

  .responsive-table .col {
    flex-basis: 100%;
  }

  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }

  .responsive-table .col:before {
    color: #6c7a89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  margin-right: 10px;
}
.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
.btn-close {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

.btn-close:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
</style>
