<template>
  <div v-if="isVisible" class="dialog-backdrop" @click.self="close">
    <div class="dialog">
      <header class="dialog-header">
        <h2>{{ title }}</h2>
        <button @click="close" class="dialog-close-button">&times;</button>
      </header>
      <main class="dialog-body">
        <slot></slot>
      </main>
      <footer class="dialog-footer">
        <slot name="footer">
          <button @click="close">Close</button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Dialog",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    isVisible: {
      get() {
        document.body.className = this.modelValue ? "dialog-open" : "";
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 581px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog-body {
  max-height: 80vh;
  overflow: auto;
}

.dialog-close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
